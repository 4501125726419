<template>
  <section>
    <b-overlay
      :show="disableUpload"
      rounded="sm"
      :key="'AddFiles' + message_uuid"
    >    
      <b-button variant="primary" @click="openLocalStorage" class="no-border" v-if="message_uuid">{{ $t('THIS_COMPUTER')}}</b-button>
      <b-button variant="primary" @click="openFileStorage" class="no-border" v-if="filestorage_enabled && message_uuid">{{ $t('FILESTORAGE.TITLE')}}</b-button>

      <template #overlay>
        <div class="text-center">
          <div v-if="!totalSize">
            {{ $t("ATTACHMENTS_PER_SIZE_LIMIT", { filesize: maxSize + "" }) }}
          </div>
          <div v-if="totalSize">
            {{ $t("ATTACHMENTS_SIZE_LIMIT", { filesize: maxSize + "" }) }}
          </div>
        </div>
      </template>

    </b-overlay>

      <b-alert show variant="info" class="mb-2" v-if="!message_uuid && cannotUpload">{{ cannotUpload }}</b-alert>

      <div v-if="showTitle" class="mt-2">{{ $t("ATTACHMENTS") }}</div>

      <span
        v-if="attachments.length != 0"
        :key="'attachments_' + attachments.length"
      >
        <span
          v-for="(item, index) in attachments"
          :key="index"
          class="ms-fontSize-12 mt-2 compose-attachment"
        >
          <Attachment
            is-uploaded
            :show-download="item.attachment_uuid != ''"
            @RemoveAttachment="RemoveAttachment"
            @DownloadAttachment="DownloadAttachment"
            :item="item"
            :index="index"
          ></Attachment>
        </span>
      </span>

      <span v-if="files.length != 0" :key="'localattachments_showing_' + files.length">
        <span
          v-for="(item, index) in files"
          :key="index"
          class="ms-fontSize-12 mt-2"
        >
          <span :class="filesClass(item)">
            <Attachment
              @RemoveAttachment="RemoveFile"
              :show-download="message_uuid != ''"
              :percentage="GetPercentage(item)"
              :item="item"
              :is-error="HasError(item)"
              :index="index"
            ></Attachment>
          </span>
        </span>
      </span>

      <b-form-text id="NO_FILES_SELECTED" v-if="attachments.length == 0">
        {{ $t("NO_FILES_SELECTED") }}
      </b-form-text>

      <div class="error-text" v-if="!AllValidFilesize">
        {{ $t("ERROR.LARGE_FILES") }} {{ this.maxSize + "MB" }}
      </div>
  
      <div class="error-text" v-if="!AllValidNames">
        {{ $t("ERROR.TOO_MANY_CHARACTERS") }} {{ 256 }}
      </div>
  
      <div class="error-text" v-if="!allValidText">
        {{ $t("ERROR.CHARACTERS_NOT_ALLOWED") }} {{ notAllowedCharacters }}
      </div>

      <b-modal
          v-model="openDialog"
          :dialog-class="teams.theme"
          size="lg"
          hide-header
          no-close-on-backdrop
          visible
          ok-variant="secondary"
          centered
        >
        
        <b-alert v-if="showWarning" show variant="warning" class="text-center mt-2 pl-1 pt-1 pb-1 pr-1">
          {{  $t('MEETING_ATTACH_NEW') }}
        </b-alert>


        <template #modal-footer="{ ok, cancel, hide }">
          <div v-if="disableUpload">
            <div v-if="!totalSize">
              {{ $t("ATTACHMENTS_PER_SIZE_LIMIT", { filesize: maxSize + "" }) }}
            </div>
            <div v-if="totalSize">
              {{ $t("ATTACHMENTS_SIZE_LIMIT", { filesize: maxSize + "" }) }}
            </div>
          </div>
          <b-button v-if="!disableUpload" @click="closeDialog">{{ $t('CLOSE') }}</b-button>
        </template>

        <div v-show="fileLocation == 0">


          <FileSelect
            :key="'localFiles_' + localFiles.length"
            show-drop
            v-model="localFiles"
            :fileType="fileType"
            @added="UpdatedFiles"
            class="mt-2"
            :total-size="totalSize"
            :max-size="maxSize"
          ></FileSelect>


          <span v-if="files.length != 0" :key="'localattachments_' + files.length">
            <span
              v-for="(item, index) in files"
              :key="index"
              class="ms-fontSize-12 mt-2"
            >
              <span :class="filesClass(item)">
                <Attachment
                  @RemoveAttachment="RemoveFile"
                  :show-download="message_uuid != ''"
                  :percentage="GetPercentage(item)"
                  :item="item"
                  :is-error="HasError(item)"
                  :index="index"
                ></Attachment>
              </span>
            </span>
          </span>


        </div>
        <div v-show="fileLocation == 1">
          <SelectFiles @selected="selecteFilestorageFile" :filter="attachments" :fileType="fileType"></SelectFiles>
        </div>
        
      </b-modal>




    
  </section>
</template>
<script>
import SelectFiles from "../FileStorage/SelectFiles";
import FileSelect from "../Compose/FileSelect";
import Attachment from "../Compose/Attachment";
export default {
  components: {
    SelectFiles,
    FileSelect,
    Attachment,
  },
  props: {
    value: Array,
    maxSize: {
      default: 0,
      type: Number,
    },
    hasRecurrence: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    totalSize: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    showTitle: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    showWarning: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    fileType: {
      default: "",
      type: String,
    },
    cannotUpload: {
      default: "",
      type: String,
    },
    message_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      fileLocation: 0,
      openDialog: false,
      localFiles: [],
      files: [],
      attachments: [],
      fileStatus: [],
      loading: false,
      file_bytes: null,
      uploading: false,
      filestorage_enabled: false,
      name: "",
    };
  },
  methods: {
    openLocalStorage(){
      this.fileLocation = 0;
      this.openDialog = true;
    },
    openFileStorage(){
      this.fileLocation = 1;
      this.openDialog = true;
    },
    closeDialog(){
      this.fileLocation = 0;
      this.openDialog = false;
    },
    async CheckFilestorage() {
      if (this.user.information) {
        try {
          let result = await this.$http        
            .get(this.user.hostname + "/filestorage/enabled");
          this.filestorage_enabled = result.data;
        } catch {
          console.log("Could not CheckFilestorage");
        }
      }
    },
    FindFiles(fileItem) {
      let foundFiles =
        this.files.filter(function (item) {
          return item.name == fileItem.name;
        }).length != 0;
      let foundAttachments =
        this.attachments.filter(function (item) {
          return item.name == fileItem.name;
        }).length != 0;
      return foundFiles || foundAttachments;
    },
    ShowAttachedError(){
        this.$store.dispatch("errors/addValidations", [{
            status: "Error",
            validationMessage: this.$t('ERROR.ALREADY_ATTACHED'),
            validation: 'ERROR.ALREADY_ATTACHED'
        }]);
    },
    UpdatedFiles() {
      let files = this.localFiles;
      this.localFiles = [];
      for (let ix = 0; ix < files.length; ix++) {
        let found = this.FindFiles(files[ix]);
        if (!found) {
          this.UploadFile(files[ix], "");
        }else{
          this.ShowAttachedError();
        }
      }
    },
    selecteFilestorageFile(item) {
      let found = this.FindFiles(item);
      if (!found) {
        this.UploadFile(
          item,
          "filestorage/" + item.file_uuid + "/" + item.version
        );
      }
    },
    ValidateFileSize(bytes) {
      return bytes < this.maxSize * 1024 * 1024;
    },
    GetPercentage(fileItem) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        return found[0].percentage;
      } else {
        return 0;
      }
    },
    SetPercentage(fileItem, percentage) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        found[0].percentage = percentage;
      }
    },
    SetDone(fileItem) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        found[0].done = true;
      }
    },
    SetError(fileItem, error) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        found[0].errorMessage = error;
        found[0].done = true;
      }
    },
    HasError(fileItem) {
      let found = this.fileStatus.filter(function (item) {
        return item.name == fileItem.name;
      });
      if (found.length == 1) {
        return found[0].errorMessage != "";
      }
      return false;
    },
    RemoveFile(item) {
      this.files = this.files.filter(function (fileItem) {
        return item.name != fileItem.name;
      });
      this.fileStatus = this.fileStatus.filter(function (fileItem) {
        return item.name != fileItem.name;
      });
    },
    async RemoveAttachment(item) {
      if(this.message_uuid)
      {
        item["message_uuid"] = this.message_uuid;
        item["user_uuid"] = this.user_uuid;
        item["functionbox_uuid"] = this.functionbox_uuid;
        let self = this;
        self.loading = true;
        this.$http
          .post(
            this.user.hostname + "/sefos-message/secure/attachment/remove",
            item
          )
          .then(function () {
            self.attachments = self.attachments.filter(function (fileItem) { return fileItem.name != item.name; });
          })
          .catch(function () {});
      }else{
        this.attachments = this.attachments.filter(function (fileItem) { return fileItem.name != item.name; });
      }
    },
    async DownloadAttachment(item) {
      let message_uuid = this.message_uuid;
      if(!message_uuid)
      {
          message_uuid = item.location.replace("messages/","").split("_").slice(0,2).join(":");
      }
      if(!message_uuid)
      {
        return;
      }
      let result = await this.$http.post(
        this.user.hostname + "/download/generate",
        {
          functionbox_uuid: this.functionbox_uuid,
          user_uuid: this.user_uuid,
          message_uuid: message_uuid,
          attachment_uuid: item.attachment_uuid,
        }
      );
      let downloadUrl =
        this.user.hostname + "/download/" + result.data.download_uuid;
      this.openUrl(item, downloadUrl);
    },
    openUrl(item, downloadUrl) {
      try {
        let element = document.createElement("a");
        element.setAttribute("target", "_blank");
        element.style = "display: none";
        element.href = downloadUrl;
        element.download = item.name;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch {
        console.log("COULD NOT OPEN ATTACHMENT");
      }
    },
    async UploadFile(item, location) {
      this.fileStatus.push({
        name: item.name,
        percentage: 0,
        errorMessage: "",
        done: false,
      });
      this.files.push(item);
      if (!this.ValidateFileSize(item.size)) {
        this.SetError(item, "FILESIZE");
        return;
      }
      if (this.totalSize) {
        if (this.TotalFilesize > this.maxSize * 1024 * 1024) {
          this.SetError(item, "FILESIZE");
          return;
        }
      }
      let self = this;
      let formData = new FormData();
      if (location == "") {
        formData.append("attachment", item);
      }
      formData.append("message_uuid", this.message_uuid);
      formData.append("functionbox_uuid", this.functionbox_uuid);
      formData.append("user_uuid", this.user_uuid);
      formData.append("location", location);
      this.$http
        .post(
          this.user.hostname + "/sefos-message/secure/attachment",
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentage = Math.floor((loaded * 100) / total);
              self.SetPercentage(item, percentage);
            },
          }
        )
        .then((result) => {
          self.SetDone(item);
          self.files = self.files.filter(function (fileItem) {
            return item.name != fileItem.name;
          });
          self.localFiles = self.localFiles.filter(function (fileItem) {
            return item.name != fileItem.name;
          });
          self.fileStatus = self.fileStatus.filter(function (fileItem) {
            return item.name != fileItem.name;
          });
          self.attachments.push(result.data);
        })
        .catch(() => {
          self.SetError(item, "COULD_NOT_UPLOAD");
        });
    },
    filesClass(item) {
      if (this.HasError(item)) {
        return ["is-invalid"];
      }
      if (!this.ValidateFileSize(item.size)) {
        return ["is-invalid"];
      }
      if (item.name.length > 256) {
        return ["is-invalid"];
      }
      if (!this.validateLettersAndNumbers(item.name)) {
        return ["is-invalid"];
      }
      return ["is-valid"];
    },
  },
  computed: {
    ValidTotalSize() {
      return this.TotalFilesize < this.maxSize * 1024 * 1024;
    },
    disableUpload() {
      return !this.AllValidFilesize;
    },
    TotalFilesize() {
      let size = 0;
      this.attachments.forEach((item) => {
        size += item.size;
      });
      this.files.forEach((item) => {
        size += item.size;
      });
      return size;
    },
    AllValidFilesize() {
      if (!this.totalSize) {
        for (let ix = 0; ix < this.files.length; ix++) {
          if (!this.ValidateFileSize(this.files[ix].size)) {
            this.$emit('valid', false);
            return false;
          }
        }
        this.$emit('valid', true);
        return true;
      }else{
        this.$emit('valid', this.ValidTotalSize);
        return this.ValidTotalSize;
      }
    },
    AllValidNames() {
      for (let ix = 0; ix < this.files.length; ix++) {
        if (this.files[ix].name.length > 256) {
          return false;
        }
      }
      return true;
    },
    allValidText() {
      for (let ix = 0; ix < this.files.length; ix++) {
        if (!this.validateLettersAndNumbers(this.files[ix].name)) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    attachments(files) {
      this.$emit("input", files);
    },
  },
  mounted() {
    this.CheckFilestorage();
    this.attachments = this.value;
  },
};
</script>
<style>
</style>